import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import a from '../assets/1.png';
import b from '../assets/2.png';
import c from '../assets/3.png';
import d from '../assets/4.png';
import e from '../assets/5.png';
import f from '../assets/6.png';
import g from '../assets/9.png';
import h from '../assets/8.png';
import './slider.css'; // Import the CSS file

const ImageSlider = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  // Custom arrow components
  const CustomPrevArrow = (props) => (
    <div className="slick-prev" onClick={props.onClick}>
      &lt; {/* Left arrow icon */}
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="slick-next" onClick={props.onClick}>
      &gt; {/* Right arrow icon */}
    </div>
  );

  const settings = {
    dots: false, // Disable default dots
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    prevArrow: <CustomPrevArrow />, // Use custom prev arrow
    nextArrow: <CustomNextArrow />, // Use custom next arrow
    beforeChange: (current, next) => setActiveSlide(next), // Update active slide
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 945,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index); // Go to the selected slide
  };

  return (
    <div className="custom-slider-container">
      <Slider ref={sliderRef} {...settings}>
        <div className="custom-slide">
          <img src={a} alt="Photo 1" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={b} alt="Photo 2" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={c} alt="Photo 3" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={d} alt="Photo 4" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={e} alt="Photo 5" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={f} alt="Photo 6" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={g} alt="Photo 7" className="slide-image" />
        </div>
        <div className="custom-slide">
          <img src={h} alt="Photo 8" className="slide-image" />
        </div>
      </Slider>

      {/* Custom dots - Only show 6 dots */}
      <ul className="custom-dots">
        {[...Array(6)].map((_, index) => (
          <li
            key={index}
            className={`custom-dot ${activeSlide === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </ul>
    </div>
  );
};

export default ImageSlider;