import React from "react";
import { Link } from "react-router-dom";
import { FaRegFlag } from "react-icons/fa6";
import { TbGiftCard } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";



import './Footer.css';
import footerimg from '../../assets/footerimg.jpg'; // Ensure this is a high-resolution image
import logo from '../../assets/belloblacklogo.PNG'
const Footer = () => {
  return (
    <>
    <div className="footermain">
      <div className="insidefootercontent">
        <div className="footerimg">
          <img src={footerimg} alt="Footer" /> {/* Add alt text for accessibility */}
        </div>
        <div className="footercontents">
          
          <div className="logobellofooter">
            <img src={logo} alt="bellologo"></img>
          </div>

          <div className="textinfooter">
              <h1>Come Visit Us</h1>
              <div className="twofooter">
                <div>
                  <h3>Products</h3>
                  <div className="bottomfooterprod">
                  <p>Spacebar Backpack</p>
                  <p>Venturepack Backpack</p>
                  <p>Classic Backpack</p>
                  <p>Blaze Backpack</p>
                  <p>Essential Backpack</p>
                  </div>
                </div>
                <div>
                  <h3>Showroom</h3>
                  <div className="bottomfooterprod">
                  <p>Kumaripati, Lalitpur</p>
                  <p className="getdirection">Get Directions <FaLocationDot /> </p>
                  <div className="socialsfooter">
                  <a href="https://www.instagram.com/bello_nepal/" target="0" className="socalsclass"><GrInstagram size={27} className="footericons"/></a>
                  <a href="https://www.youtube.com/@BelloNepal" target="0" className="socalsclass"><FiYoutube size={33} className="footericons"/></a>
                  <a href="https://www.tiktok.com/@bello_nepal" target="0" className="socalsclass"><FaTiktok size={25} className="footericons"/></a>

                  </div>
                  </div>
                </div>
                <div>
                  <h3>Opening Hours</h3>
                  <div className="bottomfooterprod">
                  <p>Open Sun-Fri 10am-6:30pm</p>
                  <p>bellofornepal@gmail.com</p>
                  <p>+977 9702651054</p>
                  <p></p>
                  </div>
                </div>
                <div className="copfooter">&copy; Bello reserves all rights and trademarks associated with its products.</div>

              </div>

            </div>

        </div>

      </div>
    </div>

    <div className="mobilefooter">
      <div className="textinfootermob">
              <h1>Come Visit Us</h1>
              <div className="twofootermob">
                <div>
                  <h3>Products</h3>
                  <div className="bottomfooterprod">
                  <p>Spacebar Backpack</p>
                  <p>Venturepack Backpack</p>
                  <p>Classic Backpack</p>
                  <p>Blaze Backpack</p>
                  <p>Essential Backpack</p>
                  </div>
                </div>
                <div>
                  <h3>Showroom</h3>
                  <div className="bottomfooterprod">
                  <p>Kumaripati, Lalitpur</p>
                  <p className="getdirection">Get Directions <FaLocationDot /> </p>
                  <div className="socialsfootermob">
                  <GrInstagram size={27} className="footericons"/>
                  <FiYoutube size={33} className="footericons"/>
                  <FaTiktok size={25} className="footericons"/>

                  </div>
                  </div>
                </div>
                <div>
                  <h3>Opening Hours</h3>
                  <div className="bottomfooterprod">
                  <p>Open Sun-Fri 10am-6:30pm</p>
                  <p>bellofornepal@gmail.com</p>
                  <p>+977 9702651054</p>
                  <p></p>
                  </div>
                </div>

              </div>

            </div>

            <div className="footerimgmob">
          <img src={footerimg} alt="Footer" /> {/* Add alt text for accessibility */}

        </div>
        <div className="copfootermobile">&copy; Bello reserves all rights and trademarks associated with its products.</div>


    </div>
</>

  );
};

export default Footer;